<template>
  <div class="min-h-screen h-full bg-white">

    <TsHeader data-testid="header" />

    <div>
      <slot />
    </div>

    <TsFooter data-testid="footer" />

  </div>
</template>